@import '../../style/abstracts/_abstracts-dir';

.bg-cover {
  background-size: cover !important;
}

.text-white {
  color: #fff !important;
}

.text-center {
  text-align: center !important;
}

.maintenance-msg {
  padding-top: 8rem !important;
  font-size: 25px !important;
}

.pb-5, .py-5 {
  padding-bottom: 1rem !important;
}

.jumbotron {
  background: url(https://bootstrapious.com/i/snippets/sn-static-header/background.jpg);
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.font-weight-bold {
  font-weight: 300 !important;
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 200;
  line-height: 1.2;
}

.jumbotron {
  height: 600px;
  background-size: cover;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .display-4 {
    font-size: 2rem !important;
    padding-top: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .container {
    max-width: 960px;
  }
  .display-4 {
    font-size: 2rem !important;
    padding-top: 400px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
  .container {
    max-width: 540px;
  }
  .display-4 {
    font-size: 1.5rem !important;
    padding-top: 400px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 576px) {
  .display-4 {
    font-size: 1rem !important;
    font-weight: bold;
    padding-top: 400px;
  }
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 400px) {
  .display-4 {
    font-size: 1rem !important;
    font-weight: bold;
    padding-top: 400px;
  }
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}